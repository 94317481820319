/**
 * @file Load CSS into shadow DOM
 */

import { cssStylesheetSupport } from "../cssom";

export interface Deregister {
  (): void;
}

export interface StyleInjector {
  register(root: ShadowRoot): Deregister;
}

export interface StyleManager extends StyleInjector {
  load(css: string): void;
}

class StylesheetLoader implements StyleManager {
  #stylesheets: CSSStyleSheet[] = [];
  readonly #roots = new Set<ShadowRoot>();

  #install(root: ShadowRoot) {
    root.adoptedStyleSheets = this.#stylesheets;
  }

  async load(css: string) {
    const stylesheet = new CSSStyleSheet();
    await stylesheet.replace(css);
    this.#stylesheets = [...this.#stylesheets, stylesheet];
    for (const root of this.#roots) {
      this.#install(root);
    }
  }

  register(root: ShadowRoot) {
    this.#roots.add(root);
    this.#install(root);
    return () => {
      this.#roots.delete(root);
    };
  }
}

class InlineStyleLoader implements StyleManager {
  readonly #css: string[] = [];
  readonly #roots = new Set<ShadowRoot>();

  load(css: string) {
    this.#css.push(css);
    for (const root of this.#roots) {
      this.#install(root, css);
    }
  }

  register(root: ShadowRoot) {
    this.#roots.add(root);
    for (const css of this.#css) {
      this.#install(root, css);
    }
    return () => {
      this.#roots.delete(root);
    };
  }

  #install(root: ShadowRoot, css: string) {
    const element = root.ownerDocument.createElement("style");
    element.textContent = css;
    root.appendChild(element);
  }
}

export function createStyleManager() {
  const manager: StyleManager = cssStylesheetSupport()
    ? new StylesheetLoader()
    : new InlineStyleLoader();
  return manager;
}
