import { lazy } from "@redotech/util/cache";

export const cssStylesheetSupport: () => boolean = lazy(() => {
  try {
    // recording result of call in a variable to trick javascript into not minifying this code
    const x = new CSSStyleSheet(); // Safari does not support before 16.4
    return !!x;
  } catch {
    return false;
  }
});
