import MuiModal from "@mui/material/Modal";
import { memo, ReactNode, useEffect } from "react";
import { IconButton } from "./button";
import XIcon from "./icon-old/x.svg";
import * as lightboxCss from "./lightbox.module.css";

export const Lightbox = memo(function Lightbox({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  useEffect(() => {
    // Blocks background scrolling while lightbox is open
    document.documentElement.style.overflow = open ? "hidden" : "";
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [open]);
  return (
    <MuiModal
      className={lightboxCss.lightboxOverlay}
      onClose={onClose}
      open={open}
    >
      <div className={lightboxCss.lightboxContent}>
        {children}
        <IconButton className={lightboxCss.closeButton} onClick={onClose}>
          <XIcon className={lightboxCss.closeButtonIcon} />
        </IconButton>
      </div>
    </MuiModal>
  );
});
