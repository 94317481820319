
      import API from "!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_style-loader_3.3.2-dc3fc578/files/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_style-loader_3.3.2-dc3fc578/files/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_style-loader_3.3.2-dc3fc578/files/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_style-loader_3.3.2-dc3fc578/files/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_style-loader_3.3.2-dc3fc578/files/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "../../../shopify-extension/src/_webpack/style-tag-transform.js";
      import content, * as namedExport from "!!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/cjs.js??css!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_postcss-loader_7.2.4-dc3fc578/files/dist/cjs.js??postcss!./tooltip.module.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/cjs.js??css!../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_postcss-loader_7.2.4-dc3fc578/files/dist/cjs.js??postcss!./tooltip.module.css";
       export default content && content.locals ? content.locals : undefined;
