import {
  FieldName,
  FieldType,
  singleFieldFormat,
  stringFieldValueFormat,
} from "./semantics/fields";

/**
 * X-Requested-With
 */
export const XRequestedWithField = new FieldType(
  new FieldName("X-Requested-With"),
  singleFieldFormat(stringFieldValueFormat),
);
