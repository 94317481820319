export const MAXIMUM_FILE_SIZE_MB = 25;

export enum ConversationFileUploadError {
  UNEXPECTED_ERROR = "Unexpected error",
  UNPERMITTED_FILE_TYPE = "Unpermitted file type",
  FILE_TOO_LARGE = "File too large",
}

export const conversationFileUploadErrorMessages: Record<
  ConversationFileUploadError,
  string
> = {
  [ConversationFileUploadError.UNEXPECTED_ERROR]:
    "An unexpected error occurred.",
  [ConversationFileUploadError.UNPERMITTED_FILE_TYPE]:
    "The file type you are trying to upload is not permitted",
  [ConversationFileUploadError.FILE_TOO_LARGE]: `The file you are trying to upload is too large. The maximum file size is ${MAXIMUM_FILE_SIZE_MB}MB`,
};
