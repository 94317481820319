import { emailIsValid } from "@redotech/email";
import { ValidationError, Validator } from "@redotech/ui/form";

export const colorValidator: Validator<string> = (color) => {
  const errors: string[] = [];
  if (!/^#[0-9A-Fa-f]{6}$/.test(color)) {
    errors.push("Color must be in the format #000000");
  }
  return errors;
};

export const optionalColorValidator: Validator<string | undefined> = (
  color,
) => {
  return color ? colorValidator(color) : [];
};

/**
 * Validates email format
 */
export const emailValidator: Validator<string> = (value) => {
  const errors: ValidationError[] = [];
  if (value && !emailIsValid(value)) {
    errors.push("Invalid email");
  }
  return errors;
};

export const selectorValidator: Validator<string> = (value) => {
  const errors: string[] = [];
  try {
    value && document.querySelector(value);
  } catch {
    errors.push("Selector must have valid syntax");
  }
  return errors;
};

export const httpsUrlValidator: Validator<string> = (value) => {
  const errors: ValidationError[] = [];
  let url: URL;
  try {
    url = new URL(value);
  } catch {
    errors.push("Invalid URL");
    return errors;
  }
  if (url.protocol !== "https:") {
    errors.push("Must be HTTPS");
  }
  return errors;
};
