import {
  Theme as MuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { RedoClientProvider } from "@redotech/redo-web/client";
import { IpifyGeoClientProvider } from "@redotech/redo-web/ipify";
import { ExtensionShopifyClient } from "@redotech/shopify-client/ajax";
import { ReactNode } from "react";
import { ShopifyExtensionClientProvider } from "./client/client-context";
import { ExtensionShopifyClientContext } from "./shopify";

export const RedoRoot = ({
  children,
  extensionShopifyClient,
  widgetId,
  storeUrl,
  muiTheme,
}: {
  children: ReactNode;
  extensionShopifyClient: ExtensionShopifyClient | undefined;
  widgetId?: string;
  storeUrl?: string;
  muiTheme?: MuiTheme;
}) => {
  if (extensionShopifyClient) {
    children = (
      <ExtensionShopifyClientContext.Provider value={extensionShopifyClient}>
        {children}
      </ExtensionShopifyClientContext.Provider>
    );
  }
  if (muiTheme) {
    children = <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
  }

  return (
    <IpifyGeoClientProvider apiKey={process.env.IPIFY_API_KEY!}>
      <RedoClientProvider url={process.env.REDO_API_URL!}>
        <ShopifyExtensionClientProvider storeUrl={storeUrl} widgetId={widgetId}>
          {children}
        </ShopifyExtensionClientProvider>
      </RedoClientProvider>
    </IpifyGeoClientProvider>
  );
};
