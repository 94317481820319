export const EMAIL_REGEX =
  /^([\w-+.]+@([\w-]+\.)+[\w-]{1,63})(,\s*[\w-+.]+@([\w-]+\.)+[\w-]{1,63})*$/;

export function emailIsValid(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export function extractEmail(string: string): string {
  const match: string[] | null = string.match(EMAIL_REGEX);
  return match ? match[0] : "";
}
