export type ENUM = { [name: string]: any };
export type STRING_ENUM = { [name: string]: string };

export function isEnumValue<T extends ENUM>(x: unknown, e: T): x is T[keyof T] {
  return Object.values(e).includes(x);
}

export function getNumericEnumValues<T extends ENUM>(e: T): number[] {
  return Object.values(e).filter((value) => typeof value === "number");
}
