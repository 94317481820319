import { XRequestedWithField } from "@redotech/http/ajax";
import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { API_CLIENT_ORIGIN } from "@redotech/redo-model/headers/requested-with-field";
import axios, { AxiosHeaders, AxiosInstance } from "axios";

export class RedoClient {
  readonly client: AxiosInstance;

  constructor(
    base: string,
    private readonly token?: string,
  ) {
    const headers = new AxiosHeaders();
    const clientVersion =
      typeof process !== "undefined"
        ? process.env?.REDO_API_CLIENT_VERSION || "_"
        : "_";
    headers.set(
      XRequestedWithField.name.value,
      `${API_CLIENT_ORIGIN}/${clientVersion}`,
    );
    this.client = axios.create({ baseURL: base, headers });
  }

  authorization() {
    if (!this.token) {
      throw new Error("Missing token");
    }
    return {
      [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
        bearerCredentialsFormat.write(this.token),
      ),
    };
  }

  readonly $type: typeof RedoClient.$TYPE = RedoClient.$TYPE;

  static readonly $TYPE = Symbol(this.name);
}
