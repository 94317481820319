import { memo } from "react";
import { Lightbox } from "./lightbox";

export const ImageLightbox = memo(function ImageLightbox({
  open,
  imageSrc,
  videoSrc,
  onClose,
}: {
  open: boolean;
  imageSrc?: string;
  videoSrc?: string;
  onClose: () => void;
}) {
  const adjustImageSize = (e: any) => {
    const image = e.target;
    const maxWidth = window.innerWidth * 0.75;
    const maxHeight = window.innerHeight * 0.75;
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;

    const widthRatio = maxWidth / imageWidth;
    const heightRatio = maxHeight / imageHeight;
    const bestRatio = Math.min(widthRatio, heightRatio);

    if (bestRatio < 1) {
      // Only scale down if necessary
      image.style.width = `${imageWidth * bestRatio}px`;
      image.style.height = `${imageHeight * bestRatio}px`;
    }
  };

  const adjustVideoSize = (e: any) => {
    const video = e.target;
    const maxWidth = window.innerWidth * 0.75;
    const maxHeight = window.innerHeight * 0.75;
    const videoWidth = video.clientWidth;
    const videoHeight = video.clientHeight;

    const widthRatio = maxWidth / videoWidth;
    const heightRatio = maxHeight / videoHeight;
    const bestRatio = Math.min(widthRatio, heightRatio);

    if (bestRatio < 1) {
      // Only scale down if necessary
      video.style.width = `${videoWidth * bestRatio}px`;
      video.style.height = `${videoHeight * bestRatio}px`;
    }
  };

  return (
    <Lightbox onClose={onClose} open={open}>
      {imageSrc && (
        <img alt="Viewing" onLoad={adjustImageSize} src={imageSrc} />
      )}
      {videoSrc && (
        <video controls onLoadedData={adjustVideoSize} src={videoSrc} />
      )}
    </Lightbox>
  );
});
