import { useRequiredContext } from "@redotech/react-util/context";
import { RedoClient } from "@redotech/redo-api-client";
import { WidgetClient } from "@redotech/redo-api-client/widget";

import { ReactNode, createContext } from "react";

export const RedoClientContext = createContext<RedoClient | undefined>(
  undefined,
);

export function RedoClientProvider({
  children,
  url,
}: {
  children: ReactNode | ReactNode[];
  url: string;
}) {
  const client: RedoClient = new RedoClient(url);
  return (
    <RedoClientContext.Provider value={client}>
      {children}
    </RedoClientContext.Provider>
  );
}

export const WidgetClientContext = createContext<WidgetClient | undefined>(
  undefined,
);

export function WidgetClientProvider({
  children,
  widgetId,
}: {
  children: ReactNode | ReactNode[];
  widgetId: string;
}) {
  const client = useRequiredContext(RedoClientContext);
  const widgetClient = new WidgetClient(client, widgetId);
  return (
    <WidgetClientContext.Provider value={widgetClient}>
      {children}
    </WidgetClientContext.Provider>
  );
}
