import { StyleManager } from "@redotech/web-util/web-components/style";
import { StyleTagTransform } from "./style";

export function webComponentsStyleTagTransform(
  manager: StyleManager,
): StyleTagTransform {
  return (css) => {
    manager.load(css);
  };
}
