import { useRequiredContext } from "@redotech/react-util/context";
import { RedoClientContext } from "@redotech/redo-web/client";
import { ReactNode, createContext } from "react";
import { ShopifyExtensionClient } from "./shopify-extension-client";

export const ShopifyExtensionClientContext = createContext<
  ShopifyExtensionClient | undefined
>(undefined);

export function ShopifyExtensionClientProvider({
  children,
  widgetId,
  storeUrl,
}: {
  children: ReactNode | ReactNode[];
  widgetId?: string;
  storeUrl?: string;
}) {
  const client = useRequiredContext(RedoClientContext);
  const shopifyExtensionClient = new ShopifyExtensionClient(
    client,
    widgetId,
    storeUrl,
  );
  return (
    <ShopifyExtensionClientContext.Provider value={shopifyExtensionClient}>
      {children}
    </ShopifyExtensionClientContext.Provider>
  );
}
