import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { RedoClient } from "@redotech/redo-api-client";
import { getLocalStorageJson } from "@redotech/redo-web/utils/local-storage-wrapper";
import { CUSTOMER_WIDGET_TOKEN_KEY } from "@redotech/redo-web/utils/shared-conf";
import { AxiosInstance, AxiosResponse } from "axios";

/**
 * A client for endpoints that require authentication, and are supposed to go
 * to Redo's shopify-server directly (skip passing through the Redo CDN).
 * Unless you have reason to skip the CDN, use ShopifyExtensionClient instead.
 *
 * Valid reasons to use AuthenticatedShopifyExtensionClient include:
 * - The request headers are stripped by the CDN (e.g. required auth headers).
 * - Fresh data from the endpoint is necessary (and the request isn't frequent
 *   enough to overwhelm shopify-server).
 *
 * ShopifyExtensionClient vs AuthenticatedShopifyExtensionClient:
 * - ShopifyExtensionClient is for endpoints that don't require authentication
 *   and pass through the Redo CDN.
 * - AuthenticatedShopifyExtensionClient is for endpoints that require
 *   authentication and go directly to Redo's shopify-server.
 *
 * This distinction relies on the baseUrl passed to the RedoClient.
 *
 * TODO: refactor so there is a single client, or so the RedoClient can be
 *   more easily distinguished.
 */
export class AuthenticatedShopifyExtensionClient {
  constructor(
    private readonly redoClient: RedoClient,
    readonly storeURL?: string,
  ) {
    if (!this.storeURL) {
      throw new Error(
        "Authenticated Shopify Extension client needs a Store URL",
      );
    }
  }

  get client(): AxiosInstance {
    return this.redoClient.client;
  }

  buildCustomersURL(path: string) {
    return `customers/${path}/?storeUrl=${this.storeURL}`;
  }

  buildWidgetURL(path: string) {
    return `widgets/${path}/?storeUrl=${this.storeURL}`;
  }

  buildContactFormURL(path: string) {
    return `contact-form/${path}/?storeUrl=${this.storeURL}`;
  }

  customerWidgetAuthentication = () => {
    const token = getLocalStorageJson(CUSTOMER_WIDGET_TOKEN_KEY);

    if (!token) {
      return {};
    }
    return {
      [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
        bearerCredentialsFormat.write(token),
      ),
    };
  };
}

/**
 * GET /customers/favorite-product
 */
export async function isFavoriteProduct(
  client: AuthenticatedShopifyExtensionClient,
  productHandle: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<boolean> {
  const url = variantId
    ? `favorite-product/${productHandle}/${variantId}`
    : `favorite-product/${productHandle}`;
  const response = await client.client.get(client.buildCustomersURL(url), {
    headers: client.customerWidgetAuthentication(),
    signal,
  });
  return response.status === 200;
}

/**
 * POST /customers/favorite-product
 */
async function setFavoriteProduct(
  client: AuthenticatedShopifyExtensionClient,
  shouldSetAsFavoriteProduct: boolean,
  productHandle: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<AxiosResponse> {
  const response = await client.client.post(
    client.buildCustomersURL("favorite-product"),
    {
      productHandle,
      variantId,
      shouldSetAsFavorite: shouldSetAsFavoriteProduct,
    },
    {
      headers: client.customerWidgetAuthentication(),
      signal,
    },
  );

  return response;
}

export async function addFavoriteProduct(
  client: AuthenticatedShopifyExtensionClient,
  productHandle: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<boolean> {
  const response = await setFavoriteProduct(
    client,
    true,
    productHandle,
    variantId,
    {
      signal,
    },
  );
  return response.status === 200;
}

export async function removeFavoriteProduct(
  client: AuthenticatedShopifyExtensionClient,
  productHandle: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<boolean> {
  const response = await setFavoriteProduct(
    client,
    false,
    productHandle,
    variantId,
    {
      signal,
    },
  );
  return response.status === 200;
}
