import { RedoClient } from "@redotech/redo-api-client";
import { ReactNode, createContext, useMemo } from "react";
import { AuthenticatedShopifyExtensionClient } from "./authenticated-shopify-extension-client";

export const AuthenticatedShopifyExtensionClientContext = createContext<
  AuthenticatedShopifyExtensionClient | undefined
>(undefined);

/**
 * baseUrl: The base URL for the Redo shopify-server (not using the Redo CDN).
 */
export function AuthenticatedShopifyExtensionClientProvider({
  baseUrl,
  children,
  storeUrl,
}: {
  baseUrl?: string | undefined;
  children: ReactNode | ReactNode[];
  storeUrl?: string;
}) {
  const shopifyExtensionClient = useMemo(() => {
    if (!baseUrl) {
      return undefined;
    }

    const client = new RedoClient(baseUrl);
    return new AuthenticatedShopifyExtensionClient(client, storeUrl);
  }, [baseUrl, storeUrl]);

  return (
    <AuthenticatedShopifyExtensionClientContext.Provider
      value={shopifyExtensionClient}
    >
      {children}
    </AuthenticatedShopifyExtensionClientContext.Provider>
  );
}
