import { IpifyGeoClient } from "@redotech/ipify-client";
import { ReactNode, createContext } from "react";

export const IpifyGeoClientContext = createContext<IpifyGeoClient | undefined>(
  undefined,
);

export function IpifyGeoClientProvider({
  apiKey,
  children,
}: {
  apiKey: string;
  children: ReactNode | ReactNode[];
}) {
  const client = new IpifyGeoClient(apiKey);
  return (
    <IpifyGeoClientContext.Provider value={client}>
      {children}
    </IpifyGeoClientContext.Provider>
  );
}
