import axios from "axios";

export interface CountryLocation {
  country: string;
  region: string;
  timezone: string;
}

export interface CountryCityLocation extends CountryLocation {
  postalCode: string;
  city: string;
}

export interface AutonomousSystem {
  asn: string;
  name: string;
  route: string;
  domain: string;
  type: string;
}

export interface IpCountry {
  as: AutonomousSystem;
  ip: string;
  isp: string;
  location: CountryLocation;
}

export interface IpCountryCity extends IpCountry {
  location: CountryCityLocation;
}

export class IpifyGeoClient {
  constructor(readonly apiKey: string) {}

  readonly axios = axios.create({
    baseURL: "https://geo.ipify.org/api/v2",
  });
}

export async function getCountry(
  client: IpifyGeoClient,
  { signal }: { signal?: AbortSignal },
): Promise<IpCountry> {
  const response = await client.axios.get("/country", {
    params: { apiKey: client.apiKey },
    signal,
  });
  return response.data;
}

export async function getCountryCity(
  client: IpifyGeoClient,
  { signal }: { signal?: AbortSignal },
): Promise<IpCountryCity> {
  const response = await client.axios.get("/country,city", {
    params: { apiKey: client.apiKey },
    signal,
  });
  return response.data;
}
